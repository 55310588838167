// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tabtrap-sentinel-6hAM0Zztmwr{display:block;height:0;width:0}", "",{"version":3,"sources":["webpack://client/shared/tabtrap/tabtrap.scss"],"names":[],"mappings":"AAAA,8BACE,aAAA,CACA,QAAA,CACA,OAAA","sourcesContent":[".sentinel {\n  display: block;\n  height: 0;\n  width: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sentinel": "tabtrap-sentinel-6hAM0Zztmwr"
};
export default ___CSS_LOADER_EXPORT___;
