// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../styles/utils.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".stretch-stretch-5OVeAMslmwr{}.stretch-vertical-4Y2xqGxDmwr{flex-direction:column}.stretch-centered-5BqPUbhfmwr{align-items:center}", "",{"version":3,"sources":["webpack://client/shared/stretch/stretch.scss"],"names":[],"mappings":"AAAA,6BACE,CAGF,8BAEE,qBAAA,CAGF,8BACE,kBAAA","sourcesContent":[".stretch {\n  composes: stretch from '../styles/utils.scss';\n}\n\n.vertical {\n  composes: stretch;\n  flex-direction: column;\n}\n\n.centered {\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stretch": "stretch-stretch-5OVeAMslmwr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["stretch"] + "",
	"vertical": "stretch-vertical-4Y2xqGxDmwr stretch-stretch-5OVeAMslmwr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["stretch"] + "",
	"centered": "stretch-centered-5BqPUbhfmwr"
};
export default ___CSS_LOADER_EXPORT___;
