// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../styles/utils.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tab-sections-tab-section-container-qDDxzqEZmwr{position:relative;height:100%}.tab-sections-tab-section-3BLB7kLHmwr{width:100%}.tab-sections-tab-section-hidden-5LbkQBctmwr{display:none}", "",{"version":3,"sources":["webpack://client/shared/tabs/tab-sections.scss"],"names":[],"mappings":"AAEA,gDAEE,iBAAA,CACA,WAAA,CAGF,sCAEE,UAAA,CAGF,6CACE,YAAA","sourcesContent":["@import \"~@css/vars.scss\";\n\n.tab-section-container {\n  composes: flex-grow-column from '~@css/utils.scss';\n  position: relative;\n  height: 100%;\n}\n\n.tab-section {\n  composes: flex-grow-column from '~@css/utils.scss';\n  width: 100%;\n}\n\n.tab-section-hidden {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabletScreen": "768px",
	"smallScreen": "1100px",
	"mediumScreen": "1300px",
	"largeScreen": "1500px",
	"azure": "#006fc8",
	"cyan": "#1faece",
	"primaryText": "var(--primary-text)",
	"secondaryText": "var(--secondary-text)",
	"chartGridColor": "var(--ui-divider)",
	"gray10": "#dedede",
	"msDarkBlue": "#002050",
	"amberLight2": "#f7e28b",
	"violetLight2": "#cec0ec",
	"tealLight3": "#cfece8",
	"bannerBlue": "var(--banner-blue)",
	"spaceXXS": "4px",
	"spaceXS": "8px",
	"spaceS": "16px",
	"spaceM": "20px",
	"spaceXM": "24px",
	"spaceL": "32px",
	"spaceXL": "40px",
	"spaceXXL": "60px",
	"panelSlideDuration": "200ms",
	"panelFadeDuration": "100ms",
	"ccBannerHeight": "46px",
	"tab-section-container": "tab-sections-tab-section-container-qDDxzqEZmwr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["flex-grow-column"] + "",
	"tabSectionContainer": "tab-sections-tab-section-container-qDDxzqEZmwr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["flex-grow-column"] + "",
	"tab-section": "tab-sections-tab-section-3BLB7kLHmwr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["flex-grow-column"] + "",
	"tabSection": "tab-sections-tab-section-3BLB7kLHmwr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["flex-grow-column"] + "",
	"tab-section-hidden": "tab-sections-tab-section-hidden-5LbkQBctmwr",
	"tabSectionHidden": "tab-sections-tab-section-hidden-5LbkQBctmwr"
};
export default ___CSS_LOADER_EXPORT___;
