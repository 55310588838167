// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../node_modules/css-loader/dist/cjs.js??ref--6-1!../../../node_modules/postcss-loader/dist/cjs.js!../../../node_modules/sass-loader/dist/cjs.js!../styles/utils.scss";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".unstyled-button-unbutton-5UgWGXv8mwr{}.unstyled-button-unbutton-5UgWGXv8mwr:active{color:inherit}", "",{"version":3,"sources":["webpack://client/shared/unstyled-button/unstyled-button.scss"],"names":[],"mappings":"AAAA,sCACE,CACA,6CACE,aAAA","sourcesContent":[".unbutton {\n  composes: unbutton focus from '../styles/utils.scss'; \n  &:active {\n    color: inherit;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"unbutton": "unstyled-button-unbutton-5UgWGXv8mwr " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["unbutton"] + " " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["focus"] + ""
};
export default ___CSS_LOADER_EXPORT___;
